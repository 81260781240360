.white {
    background-color: #ffffff;
    color: #235087;
    width: 100%;
    padding-bottom: 5px;
}
.white h3 {
    width: 100%;
    margin: 0px auto;
    padding: 20px 25px 30px;
}
.white p {
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
    color: black;
}
.xRay {
    width: 100%;
    padding: 0 25px;
    margin: 10px auto 20px;
}
.xRay img {
    width: 100%;
}
.btn {
    width: 100%;
    padding: 0 25px;
    margin: 25px auto;
    display: flex;
    justify-content: flex-end;
}


@media (min-width: 768px) {
    .white .flexWrapWhite {
        display: flex;
        max-width: 95%;
        flex-direction: row-reverse;
    }
    .white {
        padding-bottom: 20px;
    }
    .white h3 {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0 30px;
    }
    .white .startWrapWhite {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }
    .white .flexWrapWhite .textImageWhite {
        width: 130%;
    }
    .xRay {
        margin: 0 0 0 10px;
        padding: 0;
    }
    .btn {
        justify-content: flex-start;
        padding: 0;
    }
}

@media (min-width: 1024px) {
    .white .flexWrapWhite .textImageWhite {
        margin: 20px 0 ;
    }
    .white .flexWrapWhite .textImageWhite p {
        width: 100%;
        padding: 0;
    }
    .white h3 {
        width: 95%;
        max-width: 1200px;
        margin: 0px auto;
    }
    .white .startWrapWhite {
        padding: 30px 0 0;
        max-width: 95%;
        margin: 0 auto;
        justify-content: start;
    }

    .white .flexWrapWhite {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
    }
    .btn {
        width: 100%;
        justify-content: flex-start;
    }
    
}

@media (min-width: 1300px) {
    .white h3 {
        width: 100%;
        max-width: 1200px;
        padding-left: 0;
    }
   .white .flexWrapWhite .textImageWhite {
        margin: 50px 0 0 0;
    }
   .white .flexWrapWhite .textImageWhite  p {
        margin-bottom: 100px;
    } 
    .white .startWrapWhite {
        max-width: 1400px;
    }
    .btn {
        width: 100%;
        max-width: 1200px;
    }
}