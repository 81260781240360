.upcomingPage {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}
.workshopWrapper {
  color: #fff;
  /* background-color: #000; */
  background: url(../../images/newsroom/our-events-banner.jpg) no-repeat;
  background-size: cover;
  background-position: left;
  height: 250px;
}
.workshop {
  max-width: 1200px;
  margin: 0 auto;
}

.workshopContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 230px;
  padding: 10px 25px;
}
.workshopContent > h1 {
  color: #6007b1;
  margin-bottom: 25px;
}
.workshopDate {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 520px;
}
.workshopDate > p {
  color: #6007b1;
  margin-bottom: 10px;
  font-weight: 500;
}

.mainContent {
  max-width: 1200px;
  margin: 0 auto;
}
.mainContent > h3 {
  color: #000;
  font-weight: 500;
  margin: 20px 0 10px 25px;
}
.mainContent > p {
  margin: 25px 22px 25px 25px;
}
.mainContent > ul {
  margin-left: 45px;
}
.mainContent > ul > li {
  margin-right: 20px;
  font-size: 17px;
  line-height: 25px;
}
.timing {
  position: relative;
  margin: 10px 0 0 25px;
  padding-bottom: 20px;
}

.timing > p {
  font-weight: 500;
  padding: 10px 0;
}
.timing > img {
  width: 65px;
  position: absolute;
  top: 0;
  right: 0;
}

.timing > a > p {
  border-bottom: 1px solid transparent;
}
.timing > a > p:hover {
  border-bottom: 1px solid#000;
}

@media (min-width: 768px) {
  .workshopWrapper {
    background-position: center;
    height: 300px;
  }
  .workshopContent {
    height: 300px;
    display: flex;
    justify-content: space-between;
    padding: 40px 25px;
  }
  .workshopContent > h1 {
    width: 450px;
  }
  .workshopDate > p {
    margin: 10px 0;
  }
  .mainContent > h3 {
    margin: 40px 0 10px 25px;
  }
  .mainContent > ul > li {
    font-size: 20px;
    line-height: 35px;
  }
  .timing > img {
    width: 120px;
  }

  .timing > p {
    padding: 15px 0;
  }
}
@media (min-width: 1024px) {
  .workshopWrapper {
    background-position: right;
    height: 350px;
  }
  .workshopContent > h1 {
    font-weight: 600;
    margin-bottom: 50px;
  }
  .workshopContent {
    height: 350px;
  }
  .mainContent > ul > li {
    font-size: 25px;
    line-height: 35px;
  }
  .profileWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
  }
  .timing > p {
    padding: 20px 0;
  }
  .timing > img {
    width: 150px;
  }
}

@media (min-width: 1200px) {
  .workshopWrapper {
    height: 400px;
  }
  .mainContent > h3 {
    margin: 45px 0 25px 0;
  }
  .mainContent > p {
    margin: 25px 0 25px 0;
  }
  .mainContent > ul {
    margin-left: 25px;
  }
  .workshopContent {
    padding: 90px 0 90px 0;
  }
  .workshopDate > p {
    margin-bottom: 25px;
  }
}
