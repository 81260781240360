.bannerContent {
  max-width: 1200px;
  margin: 0 auto;
}
.bannerContent > h1 {
  margin: 0px 0 25px 25px;
  padding-top: 25px;
  color: #6007b1;
}

.bannerContent > p {
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .bannerContent > p {
    font-size: 22px;
    width: 80%;
  }
}
@media (min-width: 768px) {
  .bannerContent > p {
    font-size: 28px;
    line-height: 40px;
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .bannerContent > h1 {
    margin-left: 0;
  }
  .bannerContent > p {
    margin-top: 55px;
    margin-left: 0;
    width: 65%;
  }
}
