.insightsWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.desktop {
  display: none;
  width: 100%;
}
.mobile {
  width: 100%;
  display: block;
}
.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.cards {
  max-width: 900px;
  padding: 40px 25px;
}
.cards > img {
  width: 100%;
  box-shadow: 10px 10px 20px rgba(96, 7, 177, 0.25);
  border: 1px solid #000;
}
.card {
  width: 100%;
  margin-top: -4px;
  box-shadow: 10px 10px 20px rgba(96, 7, 177, 0.2);
  padding: 25px 20px 35px 25px;
  border: 1px solid #000;
}
.card > h4 {
  color: #6007b1;
  font-size: 19px;
  font-weight: 600;
}
.cardSubheading {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.cardSubheading > p {
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
}
.card > ul {
  margin-left: 25px;
}
.card > ul > li {
  line-height: 22px;
}
.readmore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.readmore > p {
  color: #11557c;
  font-weight: 600;
}
.readmore > img {
  margin-top: 5px;
  margin-left: 5px;
}
aside {
  padding: 40px 25px;
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
.search > input {
  font-size: 17px;
  width: 85%;
  padding: 12px 5px;
  border: 1px solid #000;
}
.search > input::placeholder {
  color: rgb(161, 161, 161);
}
.search > button {
  padding: 10px 14px;
  background-color: #11557c;
}
.search > button:hover {
  background-color: #11557ca9;
}
.search > button:focus {
  background-color: #11557ca9;
}
.updates {
  padding: 25px 15px;
  box-shadow: 5px 5px 10px #11557c;
}
.updates > h4 {
  font-size: 20px;
  color: #11557c;
  margin-bottom: 40px;
}
.updates > p {
  margin-top: 30px;
  margin-bottom: 30px;
}
.updates > p > a {
  color: #11557c;
  font-weight: 500;
}
.follow {
  margin: 40px 0;
  padding: 25px 15px 35px;
  box-shadow: 5px 5px 10px #11557c;
}
.follow > h4 {
  font-size: 20px;
  color: #11557c;
  margin-bottom: 40px;
}
.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.icons > a > img {
  margin: 15px 25px;
}
.icons > a > img:hover {
  opacity: 0.8;
}
.subscribe {
  margin: 40px 0;
  padding: 25px 10px 35px;
  box-shadow: 5px 5px 10px #11557c;
}
.subscribe > h4 {
  font-size: 20px;
  color: #11557c;
  margin-bottom: 40px;
}
.inputStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inputStyles > input {
  border: 1px solid #11557c;
  font-size: 17px;
  width: 90%;
  padding: 10px;
}
.inputStyles > input::placeholder {
  color: rgb(161, 161, 161);
}
.inputStyles > button {
  background-color: #11557c;
  color: #fff;
  width: 90%;
  padding: 10px;
}

@media (min-width: 768px) {
  aside {
    position: sticky;
    top: 0;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .cards {
    max-width: 600px;
  }
  .card > h4 {
    font-size: 24px;
  }
  .cardSubheading > p {
    font-size: 18px;
  }
  .card > ul > li {
    font-size: 18px;
    line-height: 28px;
  }
  .card > p {
    font-size: 20px;
    margin: 20px 0;
  }
  .contentWrapper {
    display: flex;
    align-items: flex-start;
  }
  .search {
    max-width: 320px;
  }
  .updates {
    max-width: 320px;
  }
  .updates > p > a {
    font-size: 18px;
    line-height: 10px;
  }
  .follow {
    max-width: 320px;
  }

  .subscribe {
    max-width: 320px;
  }
}

@media (min-width: 1024px) {
  .cards {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  .cards {
    max-width: 900px;
    padding-left: 0;
  }
  .card > ul > li {
    font-size: 20px;
    line-height: 35px;
  }
  aside {
    padding-right: 0;
  }
}
