.banner {
  max-width: 1200px;
  margin: 0 auto;
  background: url("../../images/expertise/expertise_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 160px;
  display: flex;
  align-items: center;
  height: 300px;
}
.banner p {
  color: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.description {
  max-width: 1400px;
  width: 100%;
  padding: 30px 25px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
}
.leftTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}
.leftTop img,
.rightBottom img {
  width: 100%;
}
.rightBottom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
}
.tools {
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 40px;
  background-color: #fff;
}
.tools h3 {
  color: #6007b1;
  text-align: center;
  padding-bottom: 25px;
}
.desktopTools {
  display: none;
}
.mobileTools {
  width: 100%;
  padding: 0 20px 20px;
  margin: 0 auto;
}
.mobileTools img {
  width: 100%;
}

@media (min-width: 768px) {
  .mobileTools {
    display: none;
  }
  .desktopTools {
    display: block;
    width: 90%;
    margin: 0 auto;
  }
  .desktopTools img {
    width: 100%;
  }
  .banner {
    height: 300px;
  }
  .banner p {
    width: 90%;
    padding: 0;
    margin: 0 auto;
    font-size: 35px;
    font-weight: 600;
    line-height: 45px;
  }
  .description {
    padding: 50px 0;
  }
  .description p {
    width: 90%;
    margin: 0 auto;
  }
  .leftTop,
  .rightBottom {
    width: 35%;
  }
}

@media (min-width: 1024px) {
  .desktopTools {
    width: 100%;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .desktopTools img {
    width: 100%;
  }
  .banner {
    background-size: cover;
    background-position: center;
    height: 350px;
  }
  .banner p {
    width: 100%;
    padding: 0 20px;
    font-size: 40px;
    line-height: 50px;
    max-width: 1200px;
  }
  .leftTop,
  .rightBottom {
    width: 25%;
  }

  .description {
    width: 100%;
    padding: 60px 0;
  }
  .description p {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .desktopTools {
    width: 100%;
    padding: 0;
    max-width: 1200px;
    margin: 0 auto;
  }
  .desktopTools img {
    width: 100%;
  }
  .banner {
    background-size: cover;
    height: 350px;
    max-width: 1400px;
  }
  .banner p {
    width: 100%;
    padding: 0;
    font-size: 40px;
    line-height: 50px;
    max-width: 1200px;
  }

  .description {
    width: 100%;
    padding: 90px 0;
  }
  .description p {
    width: 100%;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
  }
}
