.mainWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.mainHeading {
  margin: 0 20px;
}
.mainHeading > img {
  width: 100%;
  margin: 10px 0;
  max-width: 500px;
  cursor: pointer;
}
.mainHeading > img:hover {
  opacity: 0.8;
}

.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.mainContent {
  margin: 0 20px;
  padding-bottom: 25px;
}
.mainContent > p {
  margin-bottom: 10px;
}
.whyUs {
  padding-bottom: 20px;
}
.whyUs > p {
  text-align: center;
  font-weight: 700;
  margin: 0 20px;
}
.whyUs > img {
  width: 100%;
}
@media (min-width: 768px) {
  .mainHeading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainContent {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .mainHeading {
    margin-left: 0;
  }
  .mainContent {
    margin-left: 0;
  }
}
