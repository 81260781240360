.aboutUs {
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 30px;
  background-color: #ffffff;
}
.aboutUs .patterWrap {
  width: 70px;
}
.aboutUsHead {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.aboutUsHeadWrap {
  width: 92%;
  display: flex;
  justify-content: space-between;
}
.patterWrap img {
  width: 100%;
}
.aboutUsIntro {
  width: 85%;
  margin: 0 auto;
}
.aboutUs h3 {
  color: #6007b1;
  letter-spacing: 2px;
  padding: 10px 0 25px;
  font-size: 30px;
}
.aboutUsIntro p {
  max-width: 95%;
  padding-bottom: 20px;
}
.aboutUsIntro .aboutUsInfo {
  width: 100%;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  align-self: flex-start;
  cursor: pointer;
}
.aboutUsDes .bold {
  font-weight: bold;
}
.aboutUsIntro .aboutUsInfo .video {
  width: 100%;
}
.aboutUsInfo .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.aboutUsContent {
  width: 85%;
  max-width: 1200px;
  margin: 10px auto;
}
.mainHeading {
  margin: 0 20px;
}
.mainHeading > a > img {
  width: 100%;
  margin: 10px 0;
  max-width: 500px;
}
.mainHeading > a > img:hover {
  opacity: 0.8;
}

.mainContent {
  margin: 0 20px;
  padding-bottom: 25px;
}
.mainContent > p {
  margin-bottom: 10px;
}

.videoMainModal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
}
video {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
}

.whyUs {
  padding-bottom: 20px;
}
.whyUs > p {
  text-align: center;
  font-weight: 700;
  margin: 0 20px;
}
.whyUs > img {
  width: 100%;
}

.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.whyUsContainer {
  max-width: 1400px;
  background: url(../../images/aboutUs/rectangle_47.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 450px;
}
.whyUsContent {
  height: 400px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.whyUsContent > p {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
}
.whyUsContent > h2 {
  color: #6007b1;
}
.whoAreWe {
  width: 100%;
}

.whoAreWe img {
  width: 100%;
}
.whoAreWe h3 {
  width: 85%;
  margin: 0 auto;
  text-align: right;
}
.crew {
  max-width: 1200px;
  margin: 0 auto;
}
.crew h3 {
  text-align: center;
}
.crew h5 {
  color: #6007b1;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
}
.crew p {
  width: 85%;
  margin: 0 auto;
}

.crewContain {
  width: 75px;
  margin-bottom: 15px;
}
.crewContain img {
  width: 100%;
  border-radius: 50%;
}
.crewImages {
  width: 85%;
  background: url("../../images/aboutUs/logo.svg");
  min-height: 255px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
}
.crewContain .crewName {
  font-size: 14px;
  width: max-content;
  text-align: center;
  line-height: inherit;
}
.crewContain .crewDesig {
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
  line-height: inherit;
  text-align: center;
}
.crewImages .sides,
.crewImages .centre {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.crewImages .centre {
  align-self: flex-end;
}
.crewImages .sides {
  max-height: 260px;
}
.desktop {
  display: none;
}
.empty {
  width: 100%;
  height: 50px;
}

@media (min-width: 768px) {
  .aboutUsIntro {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 20px auto 0;
  }
  .aboutUsDes {
    width: 120%;
  }
  .aboutUsInfo {
    align-self: center;
  }
  .aboutUs .patterWrap {
    width: 120px;
  }
  .aboutUsHeadWrap {
    width: 95%;
  }
  .aboutUsContent {
    width: 90%;
  }
  .patterWrap img {
    width: 100%;
  }
  .aboutUs h3 {
    font-size: 35px;
    font-weight: 700;
  }
  .aboutUsDes h3 {
    padding-bottom: 40px;
  }
  .aboutUsDes p {
    font-size: 20px;
  }

  .whyUsContent > p {
    width: 70%;

    font-size: 24px;
  }
  .whyUsContent > h2 {
    font-size: 35px;
  }
  .whoAreWe h3 {
    width: 90%;
    margin: 0 auto;
  }
  .whoAreWe div {
    max-width: 600px;
  }
  .crew h3,
  .crew h5,
  .crew p {
    text-align: left;
    width: 90%;
    margin: 0 auto;
  }
  .crew h5 {
    font-size: 25px;
    margin: 10px auto 20px;
    font-weight: 500;
  }
  .crewImages {
    max-width: 600px;
  }
  .crewContain {
    width: 150px;
  }
  .crewContain .crewName {
    font-size: 20px;
  }
  .crewContain .crewDesig {
    font-size: 17px;
    line-height: 20px;
  }
  .crewImages .sides {
    min-height: 450px;
  }
  .mainHeading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainContent {
    margin-top: 30px;
  }
}
@media (min-width: 1024px) {
  .patterWrap img {
    width: 100%;
  }
  .aboutUs .aboutUsIntro {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
  }
  .aboutUs h3 {
    font-size: 38px;
    padding-top: 20px;
    align-self: flex-end;
  }
  .aboutUs .patterWrap {
    width: 150px;
  }
  .aboutUsHead {
    width: 100%;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
  }
  .aboutUsIntro .aboutUsDes {
    align-self: flex-start;
  }
  .aboutUsDes .bold {
    margin: 20px 0 0 25px;
  }
  .aboutUsContent {
    width: 95%;
  }
  .aboutUsDes > p {
    font-size: 25px;
  }
  .aboutUsHeadWrap {
    width: 100%;
  }
  .patterWrap img {
    width: 100%;
  }
  .aboutUsDes p {
    margin-left: 25px;
  }
  .aboutUsInfo {
    padding: 20px 0px 20px 20px;
    align-self: flex-end;
  }
  .aboutUsIntro {
    width: 95%;
    top: 50%;
    left: 2%;
    max-width: 1200px;
  }

  .whyUsContainer {
    height: 500px;
  }
  .whyUsContent {
    height: 450px;
  }
  .whyUsContent > p {
    margin-top: 40px;
    line-height: 1.8;
    font-size: 28px;
  }
  .whyUsContent > h2 {
    margin-bottom: 20px;
  }
  .whoAreWe h3 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-right: 25px;
  }
  .whoAreWe {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
  }
  .whoAreWe div {
    max-width: 800px;
  }
  .crew h3,
  .crew h5,
  .crew p {
    width: 90%;
    max-width: 1200px;
  }
  .crew h5 {
    margin-bottom: 25px;
  }
  .crewImages {
    width: 90%;
    max-width: 1200px;
  }
  .crewContain {
    width: 240px;
  }
  .crewContain .crewName {
    font-size: 24px;
  }
  .crewContain .crewDesig {
    font-size: 20px;
    line-height: 25px;
  }
  .crewImages .sides {
    min-height: 600px;
  }
  .empty {
    width: 100%;
    height: 60px;
  }
}

@media (min-width: 1200px) {
  .aboutUs .patterWrap {
    width: 150px;
  }
  .aboutUsHead {
    width: 100%;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
  }
  .aboutUsHeadWrap {
    width: 100%;
  }
  .whoAreWe div {
    max-width: 1000px;
  }
  .textImage h6 {
    margin-top: 10px;
  }
  .crew h5 {
    font-size: 32px;
  }
  .aboutUs h3 {
    margin-left: 0;
  }
  .aboutUsDes p {
    margin-left: 0;
  }
  .whoAreWe h3 {
    padding-right: 0;
  }
  .whoAreWe h3 {
    max-width: 1200px;
    margin: 0 auto;
  }
  .aboutUsDes .bold {
    margin-left: 0;
  }
  .aboutUsContent {
    width: 100%;
    max-width: 1200px;
  }
  .crew h5 {
    width: 100%;
    max-width: 1200px;
  }
  .crew p {
    padding: 5px 0;
    width: 100%;
    max-width: 1200px;
  }
  .whyUs > h1 {
    padding: 25px 0;
  }
  .whyUsContent {
    padding: 25px 0;
  }
  .whyUsContent > p {
    margin-top: 50px;
    width: 52%;
  }
  .whyUsContent > h2 {
    margin-bottom: 0;
  }
  .mainHeading {
    margin-left: 0;
  }
  .mainContent {
    margin-left: 0;
  }
}
