.ContactInfoWrapper {
  background-color: #11557c;
  color: #fff;
}
.contactAddress {
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px 30px;
}

.addressLinks > h3 {
  color: #fff;
  font-weight: 500;
  margin: 0 0 30px 0;
}
.addressLinks > h4 {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin: 15px 0 0 0;
}

.addressLinks > h4 > a {
  color: #fff;

  width: fit-content;
  font-size: 22px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 5px;
}
.addressLinks > h4 > a:hover {
  opacity: 0.8;
}
.address > p {
  margin: 10px 0;
  font-size: 20px;
}
.phoneNumbers > p {
  margin: 10px 0;
  font-size: 20px;
}

@media (min-width: 768px) {
  .addressWrapper {
    display: flex;
    justify-content: space-between;
    width: 680px;
  }
  .address > p {
    font-weight: 500;
    font-size: 22px;
  }
  .phoneNumbers > p {
    font-weight: 500;
    font-size: 22px;
  }
}
@media (min-width: 1024px) {
  .addressWrapper {
    width: 900px;
  }
  .address > p {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .phoneNumbers > p {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .addressLinks > h4 > a {
    font-size: 25px;
  }
  .addressLinks > h4 {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {
  .addressWrapper {
    width: 1100px;
  }
}
