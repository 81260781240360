.rightWrapper {
    display: flex;
    border: 2px solid black;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    border-right: none;
}
.ideaRightContainer {
    width: 100%;
    margin: 0 auto;
    padding: 25px;
}

.rightInfo {
    width: 200px;
    height: 150px;
}
.rightInfo p {
    font-size: 14px;
    line-height: 18px;
    text-align: right;
}
.rightInfo h4 {
    font-size: 16px;
    line-height: 20px;
    text-align: right;
}


.ideaImage {
    width: 120px;
    height: 150px;
    border-radius: 50%;
} 
.ideaImage img  {
    width: 100%;
    height: 100%;
}