.container {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.contentContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 25px 30px;
}

.description {
  margin-bottom: 20px;
}

.heroImg {
  width: 100%;
}

.discussContainer {
  margin: 0 auto;
  padding: 25px 30px;
  text-align: center;
  background-color: #11557c;
}

.discuss {
  color: #fff;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.contact {
  color: #fff;
  margin-bottom: 5px;
}

.boldGreen {
  font-weight: 500;
  color: #fff;
}

.download {
  display: inline-block;
  cursor: pointer;
  color: #11557c;
  font-weight: 500;
  margin-top: 10px;
}

.headline {
  font-weight: bold;
  color: #11557c;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  a {
    font-size: 20px;
  }

  .boldGreen {
    font-size: 22px;
  }

  .description {
    margin-bottom: 30px;
  }

  .discuss {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .boldGreen {
    font-size: 25px;
  }

  .description {
    margin-bottom: 50px;
  }

  .discuss {
    font-size: 1.8rem;
  }
}
