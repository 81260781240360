.cardContentWrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  padding-bottom: 10px;
}
.cardContentWrapper > img {
  width: 130px;
}
.occupation {
  margin: 15px auto 0;
}
.occupation > p {
  text-align: center;
  font-weight: 500;
}
.socialLinks {
  display: flex;
  justify-content: center;
}
.socialLinks > a > img {
  width: 30px;
  border-radius: 8px;
  margin: 10px;
}

@media (min-width: 768px) {
  .cardContentWrapper {
    /* width: fit-content; */
    /* flex-direction: row; */
    margin: 20px auto 0;
    padding-bottom: 30px;
  }
  .cardContentWrapper > img {
    width: 150px;
  }
  .occupation {
    margin: 0;
  }
  .occupation > p {
    margin: 0 15px;
    /* text-align: left; */
  }
  .socialLinks {
    /* justify-content: flex-start; */
    /* margin-left: 5px; */
  }
  .socialLinks > a > img {
    cursor: pointer;
    margin: 5px 10px;
  }
  .socialLinks > a > img:hover {
    opacity: 0.7;
  }
}

@media (min-width: 1024px) {
  .cardContentWrapper {
    /* width: fit-content; */
    flex-direction: row;
    margin: 20px 15px;
    padding-bottom: 30px;
  }
  .cardContentWrapper > img {
    width: 150px;
  }
  .occupation > p {
    margin: 0 0 0 20px;
    text-align: left;
  }
  .socialLinks {
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 10px;
  }
}
