.work {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #ffffff;
}
.whatWeHead {
  padding: 25px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.whatWeHeadWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
}
.pattern {
  width: 70px;
}
.pattern img {
  width: 100%;
}
.whatWeHead h2 {
  color: #6007b1;
  font-size: 28px;
  max-width: 70%;
}
.whatWe p {
  width: 100%;
  padding: 0 25px;
  margin: 0 auto 25px;
}
.artiIntel h3 {
  color: #235087;
  width: 100%;
  padding: 0 25px;
  margin: 10px auto 30px;
}
.artiIntelCenter .artiIntelImage {
  width: 100%;
  padding: 0 25px;
  margin: 20px auto;
}
.artiIntelImage img {
  width: 100%;
}
.artiIntel p {
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
}
.artiIntel p a {
  color: #000;
  width: 100%;
  margin: 10px auto;
  font-weight: 600;
}
.linkStyles p {
  margin: 15px 0;
}
.linkStyles p a {
  color: #6007b1;
}
.artiIntel span {
  font-weight: 600;
}
.projects {
  margin: 30px 0 10px;
}
.defaultBtn {
  width: 100%;
  margin: 0 auto;
  padding: 25px;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .whatWeHead {
    padding: 25px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .whatWeHeadWrap {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin-left: 0;
  }
  .whatWeHead h2 {
    font-size: 30px;
    align-self: center;
    font-size: 30px;
    width: 100%;
    margin: 0;
  }
  .pattern {
    width: 120px;
  }
  .whatWe p {
    width: 90%;
    padding: 0;
    margin: 0 auto;
  }
  .artiIntel h3 {
    width: 90%;
    margin: 25px auto 40px;
    padding: 0;
  }
  .artiIntelCenter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .artiIntelCenter .artiIntelImage {
    width: 70%;
    margin: 15px auto 0;
    padding: 0;
  }
  .artiIntelCenter .artiIntelImage img {
    width: 100%;
  }
  .artiIntelFlex {
    display: flex;
    max-width: 95%;
    flex-direction: row-reverse;
  }
  .artiIntel p {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  .defaultBtn {
    padding: 25px 0;
  }
  .linkStyles p {
    width: 90%;
    margin: 20px auto;
  }
}
@media (min-width: 1024px) {
  .whatWeHead {
    padding: 25px 0;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .whatWeHeadWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .whatWeHead h2 {
    font-size: 35px;
  }
  .pattern {
    width: 150px;
  }
  .whatWe p {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .artiIntel h3 {
    width: 95%;
    max-width: 1200px;
    margin: 30px auto;
  }
  .artiIntelCenter {
    width: 95%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-end;
  }
  .artiIntelCenter p {
    margin-top: 40px;
  }

  .artiIntelCenter .artiIntelImage {
    width: 70%;
    margin: 0 auto;
  }
  .artiIntelFlex {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .artiIntel p {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .defaultBtn {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .linkStyles > p {
    width: 90%;
    margin: 30px auto;
    padding: 30px 0;
  }
}

@media (min-width: 1300px) {
  .artiIntelCenter p {
    margin: 100px 25px 0 0;
  }
  .artiIntelCenter {
    width: 100%;
    max-width: 1400px;
  }
  .artiIntelCenter .artiIntelFlex {
    max-width: 1200px;
    justify-content: flex-end;
    margin: 0 auto;
  }
  .artiIntelCenter .artiIntelImage {
    width: 100%;
    margin: 15px auto 0;
  }
}

@media (min-width: 1024px) {
  .whatWeHead h2 {
    font-size: 35px;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .pattern {
    width: 170px;
  }
  .whatWe p {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .artiIntel h3 {
    width: 95%;
    max-width: 1200px;
    margin: 30px auto;
  }
  .artiIntelCenter {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-end;
  }
  .artiIntelCenter p {
    margin-top: 40px;
  }

  .artiIntelCenter .artiIntelImage {
    width: 70%;
    margin: 0 auto;
  }
  .artiIntelFlex {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .artiIntel p {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .artiIntel {
    padding-bottom: 20px;
  }
  .artiIntel p a {
    padding-left: 0;
  }
  .artiIntelCenter p {
    margin: 100px 25px 0 0;
  }
  .artiIntelCenter {
    width: 100%;
    max-width: 1400px;
  }
  .artiIntelCenter .artiIntelFlex {
    max-width: 1200px;
    justify-content: flex-end;
    margin: 0 auto;
  }
  .artiIntelCenter .artiIntelImage {
    width: 100%;
    margin: 15px auto 0;
  }
}

@media (max-width: 345px) {
  .whatWeHead h2 {
    max-width: 85%;
  }
}
