header {
  max-width: 1400px;
  margin: 0 auto;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1500;
  height: 64px;
  /* box-shadow: 0 4px 4px -4px rgb(0 0 0 / 16%); */
  box-shadow: 0px 4px 8px rgba(96, 7, 177, 0.5);
}

header a {
  text-decoration: none;
  color: black;
}
.headerWrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0;
  transition: all 0.2s;
}

.headerWrapperReduced {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0;
  transition: all 0.2s;
}

.logoWrapper {
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.logoWrapper > img {
  width: 100px;
  /* height: 50px; */
}
.logoWrapper > h4 {
  font-family: "Heebo", sans-serif;
  line-height: 20px;
  width: 50px;
  letter-spacing: 1px;
}

.insights {
  display: none;
  justify-content: flex-end;
  font-weight: 400;
  flex: 0.85;
}

.insights p {
  font-size: 17px;
  font-weight: 500;
}
.hamburger {
  cursor: pointer;
}
.hamburger:hover {
  opacity: 0.7;
}

@media (min-width: 760px) {
  .insights {
    position: relative;
    display: flex;
  }
  .accordionStyles {
    position: absolute;
    top: -20px;
  }
  .accordion {
    border: none;
    display: flex;
  }
  .accItem {
    border: none;
    /* display: none; */
  }
  .accHeading {
    cursor: pointer;
    text-align: center;
    padding: 10px;
  }
  .accHeading:hover {
    background-color: #235087;
    color: #fff;
  }
  .ac .accPanel {
    width: 100px;
    display: none;
  }
  .accBtn {
    text-align: center;
    border: none;
    font-size: 17px;
    font-weight: 600;
  }

  .accItem:hover .accBtnInner {
    display: block;
  }

  .accBtnInner {
    cursor: pointer;
    background-color: #f8f6f6;
    color: #235087;
    padding: 10px;
    text-align: center;
    border: none;
    font-size: 14px;
    font-weight: 600;
    display: none;
  }
  .accBtnInner:hover {
    background-color: #235087;
    color: #fff;
  }
  .accBtnInner:visited {
    display: none;
  }
  header {
    height: auto;
  }
  .headerWrapper {
    height: 90px;
  }
  .headerWrapperReduced {
    /* height: 64px; */
    height: 75px;
  }
}

@media (min-width: 1024px) {
  .accHeading {
    width: 140px;
  }
}
@media (min-width: 1300px) {
  .headerWrapper {
    padding: 0;
  }

  .headerWrapperReduced {
    padding: 0;
  }

  .insights {
    flex: 0.9;
  }
}
