.ContactInfoWrapper {
  /* background-color: #11557c; */
  color: #fff;
}
.eventAddress {
  max-width: 1200px;
  margin: 20px auto;
  /* padding: 25px 30px; */
}

.eventAddress > img {
  display: block;
  width: 100%;
  /* height: 450px; */
  /* max-width: 80%; */
  margin: 0 auto;
  object-fit: contain;
}
/* .eventDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  margin: 0 auto;
}
.eventDetails > h3 {
  color: #fff;
}
.eventDetails > h3 > span {
  color: #fff;
}
.eventDetails > p {
  font-size: 20px;
  line-height: 32px;
} */

@media (max-width: 768px) {
  .eventAddress > img {
    height: 400px;
    max-width: 450px;
  }
  .eventAddress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
  }
  /* .eventDetails {
    height: 400px;
    margin-left: 30px;
  }
  .eventDetails > h3 {
    margin-top: 0;
    max-width: 300px;
  }
  .eventDetails > p {
    font-size: 25px;
    line-height: 40px;
  } */
}
/* @media (min-width: 1024px) {
} */
