.aiAndDataWrapper {
  margin: 1em auto -32px;
  padding-bottom: 20px;
}
.tabsWrapper {
  margin: 4em auto 2em;
}
/* content  */
.aiContentWrapper {
  width: 84%;
  margin: 0 auto;
  max-width: 1200px;
}
.aiTopicHeading {
  color: #6007b1;
  margin: 1em auto;
}
.aiSubHeadingWrap {
  margin: 1em auto;
  color: #6007b1;
}

.aiContentParagraph {
  margin: 1.5em auto;
  text-align: center;
}
.aiSubHeadingWrap > h3 {
  font-size: 20px;
}
.aiImageWrap {
  margin: 1em auto;
}
.aiImageWrap img {
  width: 100%;
}

.aiImageParagraph p {
  margin: 0.5em auto 0;
}
.react-tabs__tab-list {
  border-bottom: none !important;
  width: 100% !important;
  display: inline-flex !important;
  justify-content: center;
  position: sticky;
  top: 62px;
  background-color: white;
}

.react-tabs__tab-list li {
  margin: 0 0.05em;
  width: 24.5%;
  border: none;
  color: grey;
  border-radius: unset;
  outline: none;
  font-size: 0.5em;
  padding: 0.5em 0;
  text-align: center;
  font-weight: 600;
  border-top: 5px solid rgba(35, 80, 135, 0.5);
  transition: all 0.5s ease-in-out;
}
.react-tabs__tab--selected {
  color: black;
  box-shadow: none !important;
  border-top: 5px solid #235087 !important;
}

@media screen and (min-width: 768px) {
  .aiTopicHeading {
    font-size: 35px;
  }
  .react-tabs__tab-list li {
    margin: 1em auto !important;
    border-top: 10px solid rgba(35, 80, 135, 0.5);
    font-size: 1em;
  }

  .react-tabs__tab--selected {
    color: black;
    box-shadow: none !important;
    border-top: 10px solid #235087 !important;
  }
  .react-tabs__tab-list {
    top: 50px;
  }

  .aiContentWrapper {
    width: 95%;
    margin: 0 auto;
  }
  .aiContentParagraph > h3 {
    font-size: 25px;
  }
  .aiHeadingWrapper > img {
    width: 120px;
  }
  .aiHeadingWrapper > h1 {
    font-size: 35px;
  }

  .aiImageParagraphWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .aiImageWrap {
    width: 50%;
  }

  .aiImageWrap img {
    height: 100%;
  }

  .aiImageParagraph {
    width: 50%;
    padding-right: 1%;
  }
  /* .aiImageParagraph > p {
    font-size: 1.2rem;
    line-height: 1.9rem;
  } */
}

@media screen and (min-width: 1024px) {
  .aiImageParagraphWrapper {
    /* align-items: center; */
    padding-bottom: 10px;
  }
  .aiContentParagraph > h3 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .aiImageParagraph > p {
    font-size: 1.7rem;
    line-height: 2.8rem;
  }
}
