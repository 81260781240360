.homeStyles {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}
/* .mainWrapper {
  max-width: 1200px;
  margin: 0 auto;
} */
.homebanner {
  background: url(../../images/homePage/homepage-banner.png) no-repeat;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 230px;
}
.contentStyle {
  max-width: 1200px;
  margin: 0 auto 50px;
}
.contentStyle > h1 {
  padding-top: 30px;
  margin: 0 0 20px 20px;
  color: #6007b1;
}
.contentStyle > p {
  width: 69%;
  margin-left: 20px;
  margin-bottom: 10px;
  max-width: 300px;
}

.someInfo {
  position: relative;
}

.someInfo > span > a {
  cursor: pointer;
  color: #11557c;
  font-size: 17px;
  font-weight: 600;
  position: absolute;
  left: 130px;
}

.someInfo > img {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: -80px;
}
.someInfo > img:hover {
  opacity: 0.8;
}
.venturesWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.venturesWrapper > img {
  width: 30%;
  height: fit-content;
  margin: 0 auto;
  cursor: pointer;
}

@media (min-width: 415px) {
  .homebanner {
    height: 220px;
  }
  .someInfo > span > a {
    left: 190px;
  }
  .someInfo > img {
    right: 30px;
    top: -70px;
  }
  /* .venturesWrapper > img {
    width: 125px;
    margin: 0 auto;
  } */
}

.mainCards {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.card {
  cursor: pointer;
  position: relative;
  width: 50%;
  height: 50%;
}
.card:hover {
  opacity: 0.7;
}
.card > img {
  width: 100%;
  margin: -2px 0;
}

.card > h2 {
  width: 120px;
  position: absolute;
  bottom: 25px;
  left: 10px;
  color: #fff;
  font-weight: 500;
}

.discoverContent {
  max-width: 1200px;
  margin: 25px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discoverContent > p {
  width: 80%;
  margin-right: 10px;
  font-weight: 500px;
}

.futureContent {
  max-width: 1200px;
  position: relative;
  margin: 25px 15px 25px 15px;
}
.futureContent > h1 {
  color: #235087;
  margin: 10px 0;
}
.futureContent > p {
  margin-bottom: 20px;
}

.futureContent > img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 130px;
}

.featured {
  margin: 25px auto 0;
}
.featured > h1 {
  max-width: 1200px;
  display: flex;
  align-items: center;
  color: #235087;
  margin: 30px 0 10px 15px;
}
.featured > h1 > img {
  margin-top: 5px;
  margin-left: 5px;
}

.featuredWrapper > img {
  width: 100%;
}

.ReadMoreWork {
  margin: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ReadMoreWork > p {
  width: 70%;
  margin: 15px 0;
}
.ReadMoreWork > p > span {
  font-weight: 700;
}

@media (min-width: 768px) {
  .homebanner {
    height: 300px;
  }
  .contentStyle > p {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 35px;
    max-width: 500px;
  }
  .someInfo > span > a {
    font-size: 25px;
    /* top: 100px; */
    left: 250px;
  }
  .someInfo > img {
    width: 70px;
  }
  .mainCards {
    flex-wrap: nowrap;
  }
  .card {
    margin: 2px;
  }
  .card > h2 {
    font-size: 25px;
    font-weight: 500;
  }
  .discoverContent {
    margin: 40px 25px;
  }
  .discoverContent > p {
    font-size: 25px;
    line-height: 35px;
  }
  .futureContent {
    margin: 40px 0 40px 25px;
  }
  .futureContent > h1 {
    font-size: 35px;
  }
  .futureContent > p {
    width: 90%;
    font-size: 25px;
    line-height: 35px;
    margin: 30px 0 40px;
  }
  .futureContent > img {
    width: 230px;
  }
  .featured > h1 {
    margin: 30px 25px;
    font-size: 35px;
  }
  .featuredWrapper {
    display: flex;
    max-width: 1200px;
    margin: 20px auto 30px;
  }
  .featuredWrapper > img {
    max-width: 400px;
    margin-left: 25px;
  }
  .venturesWrapper {
    display: flex;
    max-width: 1200px;
    margin: 20px auto 30px;
  }
  .venturesWrapper > img {
    max-width: 320px;
    margin-left: 25px;
  }
  .ReadMoreWork {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin: 0 20px;
  }
  .ReadMoreWork > p {
    width: fit-content;
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  .emailWrapper {
    width: 93%;
  }
  .emailWrapper > input {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .homebanner {
    background-position: center;

    height: 320px;
  }
  .someInfo > span > a {
    left: 330px;
  }
  .someInfo > img {
    width: 90px;
    right: 80px;
    top: -110px;
  }
  .featuredWrapper > img {
    max-width: 520px;
  }

  .ReadMoreWork {
    margin: 0 45px;
  }
  .ReadMoreWork > p {
    font-size: 2.3rem;
    line-height: 3.5rem;
    margin-bottom: 0;
  }
  .emailWrapper {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .card > h2 {
    bottom: 35px;
    left: 25px;
  }
  .contentStyle > h1 {
    margin-left: 0;
  }
  .someInfo > span > a {
    top: 15px;
  }
  .contentStyle > p {
    margin-top: 30px;
    margin-left: 0;
  }
  .discoverContent {
    margin: 40px auto;
  }
  .futureContent {
    margin: 40px auto;
  }
  .futureContent > p {
    margin-bottom: 60px;
  }
  .featured > h1 {
    margin: 30px auto;
  }

  .featuredWrapper > img {
    max-width: 580px;
  }
}
@media (min-width: 1400px) {
  .someInfo > img {
    right: 30px;
    /* top: -120px; */
    width: 100px;
  }
  .featuredWrapper > img {
    margin-left: 0;
  }
  .ReadMoreWork {
    margin: 0 90px;
  }
}
