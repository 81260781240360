.coursesEventsWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.courseEventsBg {
  position: relative;
  background: linear-gradient(
      92.03deg,
      rgba(0, 0, 0, 0.67) 1.47%,
      rgba(0, 0, 0, 0.09) 98.31%
    ),
    url(../../images/course-events/course-events.jpeg) no-repeat;
  height: 300px;
  background-position: center;
  background-size: cover;
  width: 100%;
}
.courseEventsTitles {
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
  padding: 40px 0 40px 25px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.courseEventsTitles > h3 {
  margin: 5px 0;
  font-size: 25px;
  color: #fff;
}

.courseEventsTitles > p {
  width: 180px;
  font-size: 18px;
  font-weight: 500;
}

.courseEventsContent {
  max-width: 1200px;
  margin: 0 auto;

  position: relative;
  padding: 25px;
}

.patterenOne {
  position: absolute;
  top: -40px;
  left: 0;
  width: 160px;
  height: 220px;
}
.patterenTwo {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 160px;
  height: 160px;
  transform: rotate(180deg);
}
.courseEventsContent > p {
  font-weight: 500;
}

.invite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  margin-top: 30px;
  padding: 0 25px;
  background-color: #11557c;
  color: #fff;
}
.invite > p {
  max-width: 1200px;
  margin: 0 auto;
}

.upcomingEvents {
  background: url("../../images/course-events/webinar.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.verticleTitle {
  position: absolute;
  top: 127px;
  right: -125px;
  color: #fff;
  background-color: rgba(96, 7, 177, 0.45);
  transform: rotate(270deg);

  padding: 23px 85px;
}
.verticleTitle > h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

.VenueDescription {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  display: flex;
}
.eventlogo {
  background: #5f07b198;
  border-top-right-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80px;
}
.eventlogo > img {
  width: 35px;
  height: 35px;
}
.eventSeeMore {
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventSeeMore > p {
  color: #fff;
  font-size: 10px;
}
.eventSeeMore > img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.eventDetails {
  padding: 3px 5px;
  color: #fff;
}
.eventDetails > h5 {
  width: 74%;
  font-size: 12px;
  font-weight: 400;
}
.eventVenue {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.eventVenue > p {
  max-width: 100px;
  line-height: 15px;
  font-size: 12px;
}
.eventArrow {
  position: absolute;
  right: 0;
  background: #000;
  border-top-left-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70px;
  height: 88px;
}
.upcomingEventArrow {
  cursor: pointer;
  width: 20px;
}
.upcomingEventArrow:hover {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .courseEventsTitles > h3 {
    font-size: 30px;
  }
  .patterenOne {
    width: 220px;
  }
  .patterenTwo {
    height: 220px;
  }
  .invite {
    height: 250px;
  }
  .upcomingEvents {
    height: 600px;
  }
  .verticleTitle {
    top: 242px;
    right: -242px;
    padding: 37px 168px;
  }
  .verticleTitle > h1 {
    font-size: 35px;
  }

  .eventlogo {
    width: 120px;
  }
  .eventlogo > img {
    width: 65px;
    height: 65px;
    padding: 10px 0;
  }
  .eventSeeMore > p {
    color: #fff;
    font-size: 14px;
  }
  .eventSeeMore > img {
    width: 15px;
    margin-left: 10px;
  }
  .eventDetails {
    padding: 5px 10px;
    color: #fff;
  }
  .eventDetails > h5 {
    width: 100%;
    font-size: 21px;
  }
  .eventVenue {
    width: 510px;
    flex-direction: row;
  }
  .eventVenue > p {
    max-width: 200px;
    margin-top: 15px;
    line-height: 25px;
    font-size: 21px;
  }
  .eventArrow {
    width: 115px;
    height: 109px;
    border-top-left-radius: 60px;
  }
  .upcomingEventArrow {
    width: 30px;
  }
}
@media (min-width: 1024px) {
  .courseEventsBg {
    background-position: bottom;
  }
  .courseEventsTitles > h3 {
    font-size: 35px;
  }

  .courseEventsContent > p {
    width: 85%;
  }
  .verticleTitle {
    top: 232px;
    right: -232px;
    padding: 47px 168px;
  }
  .upcomingEvents {
    height: 95vh;
  }
  .eventlogo {
    width: 160px;
  }
  .eventlogo > img {
    width: 70px;
    height: 70px;
    padding: 10px 0;
  }
  .eventSeeMore > p {
    color: #fff;
    font-size: 18px;
  }
  .eventSeeMore > img {
    width: 18px;
    margin-left: 12px;
  }
  .eventDetails {
    padding: 10px 15px;
    color: #fff;
  }
  .eventDetails > h5 {
    width: 85%;
    font-size: 25px;
  }
  .eventVenue {
    margin: 40px 0 0;
    width: 680px;
    flex-direction: row;
  }
  .eventVenue > p {
    max-width: fit-content;
    margin-top: 15px;
    line-height: 25px;
    font-size: 25px;
  }
  .eventArrow {
    width: 140px;
    height: 159px;
    border-top-left-radius: 80px;
  }
  .upcomingEventArrow {
    top: 45px;
    right: 50px;
  }
}

@media (min-width: 1200px) {
  .courseEventsBg {
    background-position: bottom;
    height: 400px;
  }
  .courseEventsTitles {
    padding: 80px 0 0;
  }
  .courseEventsTitles > h3 {
    font-size: 40px;
  }
  .courseEventsContent {
    padding-left: 0;
  }
  .patterenOne {
    width: 250px;
    height: 250px;
  }
  .patterenTwo {
    width: 250px;
    height: 250px;
  }
  .invite > p {
    font-size: 30px;
  }

  .upcomingEventArrow {
    top: 65px;
    right: 70px;
  }
  .eventVenue {
    margin: 40px 0 0;
    width: 1030px;
    flex-direction: row;
  }
  .eventVenue > p {
    max-width: fit-content;
    margin-top: 15px;
    line-height: 25px;
    font-size: 25px;
  }
}

@media (min-width: 1400px) {
  .verticleTitle {
    top: 208px;
    right: -208px;
    padding: 71px 168px;
  }
  .eventArrow {
    width: 185px;
    height: 159px;
    /* border-top-left-radius: 60px; */
  }
  .upcomingEventArrow {
    width: 30px;
  }
  .featuredWrapper > img {
    margin-left: 0;
  }
  .ReadMoreWork {
    margin: 0 90px;
  }
}
