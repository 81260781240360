.mainWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 15px 20px 20px;
}
.contentWrapper > p {
  text-align: justify;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .contentWrapper {
    padding-left: 0;
  }
}
