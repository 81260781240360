.newsroomWrapper {
  background-color: #fff;
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.newsroomWrapper > h1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 25px;
}
.workshopWrapper {
  margin: 30px 0 50px;
  cursor: pointer;
  color: #fff;
  /* background-color: #000; */
  background: url(../../images/newsroom/our-events-banner.jpg) no-repeat;
  background-size: cover;
  background-position: left;
  height: 250px;
}
.workshopWrapper:hover {
  opacity: 0.7;
}
.workshop {
  max-width: 1200px;
  margin: 0 auto;
}

.workshopContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 230px;
  padding: 10px 25px;
}
.workshopContent > h1 {
  color: #6007b1;
  margin-bottom: 25px;
}
.workshopDate {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 520px;
}
.workshopDate > p {
  color: #6007b1;
  margin-bottom: 10px;
  font-weight: 500;
}

.logoWrap {
  display: flex;
  align-items: center;
}
.logoWrap > img {
  width: 70px;
}
.logoWrap > p {
  text-align: center;
  width: 100px;
  margin-left: -15px;
  margin-bottom: 10px;
  font-weight: 500;
}
.organizer {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.organizer > button {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 40px;
  font-size: 22px;
  font-weight: 600;
  padding: 10px 30px;
  box-shadow: -4px -4px 8px #00c5ff, 4px 4px 8px #66b3ff;
}

.organizer > button:hover {
  opacity: 0.97;
  box-shadow: -2px -2px 4px #00c5ff, 2px 2px 4px #66b3ff;
}

.conference {
  color: #fff;
  background: linear-gradient(
      94.88deg,
      rgba(0, 0, 0, 0.5) 2.56%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    url(../../images/newsroom/conference-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.conference:hover {
  cursor: pointer;
  opacity: 0.8;
}
.conferenceWrapper {
  max-width: 1200px;
  margin: 30px auto 0;
}
.conferenceWrapper > img {
  display: block;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}
.conferenceContent {
  max-width: 1200px;
  margin: 0 auto;
}

.conferenceContent {
  padding: 10px 25px;
}

.conferenceContent h3 {
  padding-bottom: 25px;
  color: white;
}

.conferenceContent > h1 {
  margin-bottom: 25px;
}

.conferenceDate > p {
  margin-bottom: 10px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .workshopWrapper {
    background-position: center;
    height: 300px;
  }
  .workshopContent {
    height: 300px;
    display: flex;
    justify-content: space-between;
    padding: 40px 25px;
  }
  .workshopContent > h1 {
    width: 500px;
  }
  .workshopDate > p {
    margin: 10px 0;
  }
  .logoWrap > p {
    width: 150px;
    line-height: 25px;
    font-weight: 600;
  }
  .organizer {
    padding-bottom: 30px;
    padding-right: 60px;
  }
  .organizer > button {
    font-size: 30px;
    padding: 15px 60px;
  }
  .conferenceWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conferenceContent > h1 {
    font-size: 42px;
    font-weight: 600;
  }
  .conferenceWrapper > img {
    width: 270px;
    margin: 30px 0;
  }
}

@media (min-width: 1024px) {
  .workshopWrapper {
    background-position: right;
    height: 350px;
  }
  .workshopContent > h1 {
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .conferenceContent > h1 {
    /* margin-top: 30px; */
    margin-bottom: 30px;
  }
  .workshopContent {
    height: 350px;
  }
  .conferenceDate {
    display: flex;
    justify-content: space-between;
    max-width: 550px;
  }
}

@media (min-width: 1200px) {
  .newsroomWrapper > h1 {
    padding-left: 0;
    margin-top: 20px;
  }
  .workshopContent {
    padding-left: 0;
    padding-right: 0;
  }
  .workshopDate > p {
    margin-bottom: 25px;
  }
  .organizer {
    padding-right: 110px;
  }
  .conferenceContent {
    padding-right: 60px;
    padding-left: 0;
  }
  .conferenceContent > h1 {
    margin-bottom: 60px;
    margin-top: 30px;
  }
}
