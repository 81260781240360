.contactContainer {
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #fff;
}
.pattern {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.pattern img {
  width: 100px;
}
.desktop {
  display: none;
}
.firstInfo {
  width: 80%;
  margin: 0 auto;
}
.firstInfo > h3 {
  color: #6007b1;
  margin: 10px 0;
}
.firstInfo p {
  color: #4a4848;
}

.thirdInfo {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.textContainer {
  width: 80%;
  margin: 0 auto;
  padding-top: 15px;
}
.textContainer > h3 {
  color: #000;
}
.textContainer p {
  color: #4a4848;
  margin-top: 10px;
}
.thirdInfo img {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
}
.thirdInfo > p {
  width: 80%;
  margin: 10px auto;
}
form {
  width: 80%;
  margin: 0 auto;
}
form > input,
form > textarea {
  width: 100%;
  padding: 15px 30px;
  margin: 6px 0;
  border: 2px solid #3989d2;
  color: black;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
}

form > input::placeholder {
  color: rgba(0, 0, 0, 0.575);
  font-size: 16px;
}
form > textarea::placeholder {
  color: rgba(0, 0, 0, 0.575);
  font-size: 16px;
  font-weight: 400;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: black;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: black;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: black;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: black;
}
form > textarea {
  height: 100px;
}
form > button {
  cursor: pointer;
  padding: 7px 15px;
  background-color: #ffffff;
  color: black;
  border: 2px solid #3989d2;
  font-size: 20px;
}
form > button:hover {
  color: #fff;
  background-color: #3989d2;
  transition: all 0.25s ease-in;
}
form > button:focus {
  color: #fff;
  background-color: #3989d2;
  transition: all 0.25s ease-in;
}

button:disabled {
  background-color: #91989e;
  border: 2px solid #91989e;
  color: rgb(223, 223, 223);
}
button:disabled:hover {
  background-color: #91989e;
  border: 2px solid #91989e;
  color: rgb(223, 223, 223);
}
form div {
  width: 100px;
  margin: 6px auto;
}
.forthInfo {
  width: 100%;
  position: relative;
  padding-bottom: 40px;
}
form img {
  position: absolute;
  right: 0%;
  bottom: 0%;
  width: 50px;
}
.bySubmiting {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.bySubmiting > input {
  width: fit-content;
  margin: 10px;
}
.bySubmiting > p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
}
.newsLetterWrapper {
  color: #fff;
  background-color: #11557c;
  margin-top: 20px;
  padding: 25px;
}
.newsLetter {
  max-width: 1200px;
  margin: 0 auto;
}
.newsLetter > h3 {
  color: #fff;
}
.newsLetter > div {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.newsLetter > div > input {
  padding: 5px;
  border: 2px solid #e7ff55;
  width: 85%;
  max-width: 200px;
}
.newsLetter > div > button {
  background-color: transparent;
  border: 2px solid #e7ff55;
}
.newsLetter > div > button:hover {
  background-color: #fff;
  opacity: 0.4;
}
.newsLetter > div > button:focus {
  background-color: #fff;
  opacity: 0.4;
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .desktop {
    margin: 20px 0;
    display: block;
  }
  .contactContainer {
    position: relative;
  }
  .topImage {
    position: absolute;
    top: 0%;
    right: 0%;
  }
  .topImage img {
    width: 120px;
  }
  .firstInfo {
    width: 90%;
    margin: 0 auto;
  }
  .firstInfo > h3 {
    margin: 60px 0 20px 0;
    font-size: 30px;
    font-weight: 600;
  }
  .firstInfo p {
    max-width: 530px;
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .textContainer {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .textContainer > h3 {
    font-size: 30px;
    font-weight: 600;
  }
  .textContainer p {
    margin-top: 15px;
    font-size: 20px;
    line-height: 31px;
    max-width: 500px;
  }
  .thirdInfo img {
    width: 60px;
  }
  .thirdInfo > p {
    width: 90%;
  }
  form {
    width: 91%;
    margin: 20px auto;
  }
  form input {
    width: 48%;
    margin: 10px 6px;
  }
  form textarea {
    height: 150px;
    width: 98%;
    margin: 10px 0 10px 6px;
  }
  form button {
    padding: 10px 35px;
  }
  form div {
    width: 150px;
  }
  form img {
    width: 60px;
  }
  .bySubmiting > input {
    margin: 0 10px;
  }
  .bySubmiting > input[type="checkbox"] {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
  .newsLetter > h3 {
    margin: 20px 0;
  }
  .newsLetter > div > input {
    padding: 10px 15px;
    max-width: 500px;
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  .firstInfo,
  .secondInfo {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .firstInfo {
    padding-left: 25px;
  }
  .textContainer {
    width: 95%;
    margin: 0 auto;
    max-width: 1200px;
  }
  .topImage img {
    width: 150px;
  }
  .firstInfo > h3 {
    font-size: 32px;
  }
  .firstInfo p {
    max-width: 560px;
    font-size: 25px;
  }

  .textContainer > h3 {
    font-size: 32px;
  }
  .textContainer p {
    font-size: 25px;
    line-height: 35px;
    max-width: 550px;
  }

  .thirdInfo img {
    width: 75px;
  }
  .thirdInfo > p {
    width: 95%;
  }
  .forthInfo {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  form {
    width: 97%;
  }
  form textarea {
    width: 97%;
    margin: 10px 0 10px 6px;
  }
  form button {
    padding: 10px 45px;
  }
  form img {
    width: 75px;
  }
  .forthInfo {
    padding-bottom: 65px;
  }
  .bySubmiting > p {
    width: 80%;
    font-size: 17px;
    line-height: 25px;
  }
  .bySubmiting > input[type="checkbox"] {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .newsLetter > div > input {
    max-width: 600px;
  }
}

@media (min-width: 1200px) {
  form {
    width: 99%;
  }
  .textContainer {
    width: 98%;
  }
  .thirdInfo > p {
    width: 98%;
  }
  .firstInfo {
    padding-left: 15px;
  }
}
