.bannerStyles {
  max-width: 1400px;
  margin: 0 auto;
  background: url("../../images/Solution/solution-1.png"), no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
}
.CardsWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}
.CardWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.ModalContent > h1 {
  color: #e7ff55;
  margin-bottom: 10px;
}
.ModalContent > h4 {
  width: fit-content;
  color: #fff;
  padding-bottom: 2px;
  border-bottom: 2px solid #fff;
}
.ModalContent > p {
  color: #fff;
}

.solutionsTilesWrapper {
  display: flex;
  margin: 10px 0 0 25px;
  max-width: 1200px;
  /* width: 80%; */
  flex-wrap: wrap;
}

.solutionTile {
  padding: 5px 10px;
  margin-right: 10px;
  background-color: #235087;
  color: white;
  cursor: pointer;
  transition: background-color 200ms;
  min-width: 100px;
  max-width: 165px;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.activeSolutionTile {
  color: #49dcf6;
}

.solutionTile:hover {
  background-color: #4274b1;
}

@media (min-width: 768px) {
  .bannerStyles {
    height: 300px;
    /* flex-direction: row; */
  }
  .CardWrapper {
    display: flex;
    justify-content: space-between;
  }
  /* .solutionsTilesWrapper {
    flex-direction: column;
  }
  .solutionTile {
    margin-bottom: 10px;
    padding: 10px 20px;
  } */
  .solutionTile {
    padding: 8px 16px;
    width: 140px;
  }

  .solutionsTilesWrapper {
    display: flex;
    margin: 15px 0 0 25px;
  }
}

@media (min-width: 1200px) {
  .solutionsTilesWrapper {
    margin: 15px auto 0 auto;
    width: 100%;
  }

  .solutionTile {
    padding: 8px 16px;
    width: 140px;
  }

  .bannerStyles {
    height: 330px;
  }
}
