.aiWelcomeHeadingWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.innerTextWrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.innerTextWrapper > p {
  margin-left: 25px;
  margin-right: 25px;
  max-width: 500px;
  /* font-weight: 500; */
}
.aiHeadingWrapper {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0;
}
.aiHeadingContent h1 {
  color: #6007b1;
  margin-left: 20px;
}
.aiHeadingContent p {
  font-weight: 500;
  margin-left: 25px;
}
.aiHeadingWrapper img {
  width: 65px;
}

@media screen and (min-width: 768px) {
  .aiHeadingWrapper img {
    width: 120px;
  }
  .aiHeadingContent > h1 {
    font-size: 35px;
  }
  .aiHeadingContent > p {
    margin-bottom: 5px;
  }
  .innerTextWrapper > p {
    font-size: 25px;
    line-height: 35px;
    max-width: 550px;
  }
}

@media (min-width: 1024px) {
  .aiHeadingWrapper img {
    width: 150px;
  }
}
@media (min-width: 1200px) {
  .aiWelcomeHeadingWrapper > p {
    margin-left: 0;
  }

  .aiHeadingContent h1 {
    margin-left: 0;
  }
  .aiHeadingContent p {
    margin-left: 0;
  }
  .innerTextWrapper > p {
    margin-left: 0;
    max-width: 850px;
  }
}
