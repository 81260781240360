.explore {
  background-color: #fff;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.wallpaper {
  width: 100%;
  background: url("../../images/exploring-ants/innovation-lab-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  display: flex;
  justify-content: flex-start;

  /* height: 250px; */
}

.wallpaper img {
  width: 100%;
}
.wallpaper div {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
}
.wallpaper h3 {
  width: 100%;
  color: #fff;
  padding: 35px 10px 25px;
  margin: 0 auto;
}
.ideasContainer > h3 {
  color: #6007b1;
  text-align: center;
  margin-top: 25px;
}
.containerFlex {
  width: 100%;
  margin: 0px auto;
  padding: 50px 25px 80px;
}
.containerFlex img {
  width: 100%;
  z-index: 1499;
}
.ideasContainer {
  position: relative;
}
.polygonLeft {
  position: absolute;
  width: 150px;
  height: 300px;
  top: 0;
  right: 0;
  z-index: 1498;
}
.polygonLeft img,
.polygonRight img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.polygonRight {
  position: absolute;
  width: 100px;
  height: 300px;
  bottom: 0;
  left: 0;
  z-index: 1498;
  transform: rotate(180deg);
}
.logos {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  justify-content: space-between;
}
.logoHead > h3 {
  text-align: center;
  color: #6007b1;
}
.desktop {
  display: none;
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .wallpaper {
    height: 250px;
  }
  .wallpaper img {
    width: 80%;
  }
  .wallpaper div {
    width: 95%;
    padding: 40px 0;
    margin: 0 auto;
  }
  .wallpaper p {
    width: 100%;
    padding: 15px 25px 25px;
  }
  .desktop {
    display: block;
  }
  .ideasContainer {
    width: 100%;
    margin: 0 auto;
  }
  .containerFlex {
    width: 90%;
    margin: 0 auto;
    padding: 95px 0;
  }
  .polygonRight {
    top: 0;
    left: 0;
    height: 250px;
    width: 300px;
  }
  .polygonLeft {
    top: auto;
    bottom: 0;
    right: 0;
    height: 250px;
    width: 300px;
  }
  .logos {
    width: 80%;
    padding: 0 0 25px;
    margin: 0 auto;
  }
  .logoHead h3 {
    color: #6007b1;
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .wallpaper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    height: 300px;
  }
  .wallpaper img {
    width: 100%;
  }
  .wallpaper div {
    width: 95%;
    max-width: 1200px;
    padding: 70px 0;
    margin: 0 auto;
  }
  .wallpaper p {
    padding: 15px 40px 25px;
    font-size: 35px;
    line-height: 70px;
  }
  .containerFlex {
    width: 95%;
  }
  .polygonLeft,
  .polygonRight {
    width: 250px;
    height: 350px;
  }
  .logos {
    width: 95%;
    max-width: 800px;
    padding: 0 0 25px;
    margin: 0 auto;
  }
  .logoHead h3 {
    color: #6007b1;
    margin-bottom: 30px;
  }
}
@media (min-width: 1300px) {
  .wallpaper {
    height: 350px;
  }
  .wallpaper > div > h3 {
    font-size: 45px;
  }
  .wallpaper div {
    width: 100%;
  }
  .ideasContainer {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  .containerFlex {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0 100px;
  }
  .polygonRight,
  .polygonLeft {
    width: 300px;
    height: 370px;
  }
  .logos {
    width: 100%;
    margin: 0 auto 10px;
  }
}
