.mainWrapper {
  background-color: #fff;
  max-width: 1400px;
  margin: 0 auto;
}
.carouselWrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 0 0;
}
.carouselText1 {
  background: linear-gradient(
      180deg,
      rgba(96, 7, 177, 0) 0%,
      rgba(17, 85, 124, 0.8) 100%
    ),
    url("../../images/Solution/healthcare.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 30% 15% 5%;
  color: white;
}
.carouselText2 {
  background: url("../../images/Solution/other-solution.jpg"),
    linear-gradient(red, blue);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 30% 10% 0%;
  color: white;
}
.carouselText3 {
  background: url("../../images/Solution/banking.jpg"),
    linear-gradient(red, blue);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 30% 15% 5%;
  color: white;
}
.carouselText1 h1,
.carouselText2 h1 .carouselText3 h1 {
  margin: 0.5em auto;
}
.carouselText1 > h3 {
  color: #fff;
}
.carouselText2 > h3 {
  color: #fff;
}
.carouselText3 > h3 {
  color: #fff;
}
