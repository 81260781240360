* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Barlow", sans-serif;
  background-color: #e4e4e4;
}
body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #6007b1;
  border-radius: 16px;
  border: 1px solid #f5f5f5;
}
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("./images/header/slider-pattern.svg"),
    url("./images/upcomingEvents/background.png"),
    url("./images/Solution/solution-1.png");
}

h1 {
  font-size: 30px;
  font-weight: 600;
}

h2 {
  font-size: 25px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  font-weight: 600;
}

p {
  font-size: 17px;
  line-height: 25px;
}

.react-multiple-carousel__arrow {
  background-color: #ffffff00;
  font-size: 40px;
}

.react-multiple-carousel__arrow:hover,
.react-multiple-carousel__arrow:visited,
.react-multiple-carousel__arrow:focus {
  background-color: #ffffff00;
  border: none;
}

.react-multiple-carousel__arrow::before {
  font-size: 80px;
  font-weight: bold;
}

.modal-video-close-btn:hover {
  background-color: transparent;
  border: none;
}

@media (min-width: 768px) {
  h1 {
    font-size: 35px;
    font-weight: 700;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
  }
  h3 {
    font-size: 28px;
    font-weight: 600;
  }

  p {
    font-size: 20px;
    line-height: 35px;
  }
}
@media (min-width: 1024px) {
  h1 {
    font-size: 48px;
    font-weight: 700;
  }
  h2 {
    font-size: 35px;
    font-weight: 600;
  }
  h3 {
    font-size: 32px;
    font-weight: 600;
  }
  p {
    font-size: 25px;
    line-height: 35px;
  }
}
@media (min-width: 1024px) {
  p {
    font-size: 25px;
    line-height: 40px;
  }
}
