.productsWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}

.productsGrid {
  background: url("../../images/smart-products/smart-products-bg.png") no-repeat;
  display: flex;
  justify-content: center;
  width: 100%;
  background-size: cover;
}
.product {
  width: 100%;
}
.contactWrapper {
  background-color: #11557c;
  color: #fff;
}

.contactUs {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  padding: 0 25px;
}
.contactUs > button {
  margin: 15px 0 0;
  width: fit-content;
  background-color: #fff;
  color: #11557c;
  padding: 10px 20px;
  outline: none;
  border: 2px solid #11557c;
  font-size: 16px;
}
.contactUs > button:hover {
  color: #fff;
  background-color: #11557c;
  transition: all 0.25s ease-in;
  cursor: pointer;
  border: 2px solid #fff;
}
.contactUs > button:focus {
  color: #235087;
  background-color: #fff;
  transition: all 0.25s ease-in;
}

@media (min-width: 768px) {
  .product {
    width: 90%;
    max-width: 600px;
  }
  .contactUs > button {
    padding: 10px 35px;
  }
  .contactUs {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .product {
    width: 90%;
    max-width: 750px;
  }
  .contactUs > button {
    padding: 15px 50px;
  }
}
