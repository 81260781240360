.cards {
  background-color: #fff;
  padding: 10px 0 30px;
}
.card {
  cursor: pointer;
  width: 85%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-color: #235087;
}
.card > img {
  width: 100%;
}

.cardContent > h4 {
  color: white;
  padding: 10px 10px 0;
  font-size: 17px;
  font-weight: 400;
}
.cardContent > p {
  color: white;
  padding: 10px;
}

.cardContent > div > img {
  width: 60px;
  cursor: pointer;
  padding: 10px 10px 20px;
}
.card > a > img:hover {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .card {
    width: 82%;
  }
  .cardContent {
    height: 260px;
  }
  .cardContent > h4 {
    font-size: 20px;
  }
  .cardContent > p {
    margin-top: 20px;
  }
}
@media (min-width: 1024px) {
  .cardContent > h4 {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {
  .card {
    width: 95%;
  }
}
