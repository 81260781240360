
.blue {
    background-color: #235087;
    color: #ffffff;
    width: 100%;
    padding-bottom: 5px;
}
.blue h3 {
    width: 100;
    margin: 0px auto;
    padding: 20px 25px 30px;
}
.blue .startWrap {
    width: 100%;
    padding: 0 25px;
    margin: 0 auto 20px;
}
.watch {
    margin: 10px 0 20px;
}
.watch img {
    width: 100%;
}
.blueBtn {
    width: 100% ;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }


  @media (min-width: 768px) {
    .blue h3 {
        width: 90%;
        margin: 0px auto;
    }
    .blue .startWrap {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0;
        padding: 0 0 15px;
    }
    .blue .flexWrap {
        display: flex;
        max-width: 95%;
    }
    .blue .flexWrap .textImage {
        width: 100%;
    }
    .watch {
        margin: 0 10px 0 0;
    }
  }

  @media (min-width: 1024px) {
    .blue h3 {
        width: 95%;
        max-width: 1200px;
        margin: 0px auto;
    }
    .blue .startWrap {
        padding: 30px 0;
    }
    .blue .flexWrap .textImage {
        margin: 20px 0 ;
    }
    .blue .startWrap {
        max-width: 1400px;
    }
    .blue .flexWrap {
        width: 95%;
        max-width: 1200px;
        margin: 0 auto;
    }
  }

  @media (min-width: 1300px) {
      .blue h3 {
          width: 100%;
          margin: 0 auto;
          max-width: 1200px;
          padding-left: 0;
      }
    .blue .flexWrap .textImage {
        margin: 50px 0 0 20px;
    }
    .blue .flexWrap .textImage  p {
        margin-bottom: 100px;
    } 
  }
