footer {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  background-color: #181b27;
  color: white;
  padding: 5px;
}

.footerWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.logoWrapper {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 80px;
  padding-left: 5px;
}
.logoWrapper > img {
  width: 100px;
}
.logoWrapper > h4 {
  font-family: "Heebo", sans-serif;
  line-height: 15px;
  width: 50px;
  letter-spacing: 1px;
}
.linksWrapper {
  margin: 0 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.linkStyles {
  display: flex;
  flex-direction: column;
}
.linkStyles > a {
  cursor: pointer;
  font-size: 13px;
  margin: 6px 0;
}
.linkStyles > a:hover {
  opacity: 0.7;
}
.socialLinks > p {
  font-weight: 600;
  margin: 10px 0;
}
.socialLinks > a > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 10px 0 0;
}
.socialLinks > a > img:hover {
  opacity: 0.7;
}
.copyRight {
  margin: 10px 0;
  text-align: center;
  color: #3989d2;
}
@media (min-width: 415px) {
  .linkStyles {
    margin-right: 20px;
  }
}

@media (min-width: 760px) {
  .logoWrapper {
    margin-left: 10px;
  }
  .logoWrapper > img {
    width: 100px;
    /* height: 60px; */
  }
  .logoWrapper > h4 {
    line-height: 20px;
    font-size: 16px;
  }
  .footerWrapper {
    padding: 8px 0;
  }
  .linksWrapper {
    max-width: 610px;
    margin: 0 auto;
  }
  .linkStyles > a {
    font-size: 15px;
  }
  .socialLinks {
    width: 100px;
  }
  .socialLinks > p {
    font-size: 17px;
    margin: 0;
  }
  .socialLinks > a > img {
    margin: 0 10px 8px 0;
  }
  .copyRight {
    margin: 0;
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .linksWrapper {
    max-width: 860px;
  }
  .socialLinks {
    width: 110px;
  }
  .socialLinks > a > img {
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1200px) {
  .logoWrapper {
    margin: 0;
    padding: 0;
  }
  .linksWrapper {
    max-width: 1080px;
  }
  .linkStyles > a {
    font-size: 17px;
  }
  .copyRight {
    font-size: 17px;
  }
}
