.menuStyles {
  /* background: url(../../images/header/menu-bg-patterns.png) no-repeat; */
  background: url(../../images/header/menu-bg.jpg);

  background-color: #181b27;
  background-size: cover;
  color: #fff;
  width: 100%;
}
.menuWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.logoAndCloseIcon {
  /* background: url(../../images/header/slider-pattern.svg); */
  /* background-color: #181b27; */
  background-size: cover;
  color: #fff;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0px auto;
  max-width: 1200px;
}
.logoAndCloseIcon > img {
  padding: 0;
  cursor: pointer;
}
.logoAndCloseIcon > img:hover {
  opacity: 0.7;
}

.logoWrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 80px;
}
.logoWrapper > img {
  width: 100px;
  /* height: 50px; */
}
.logoWrapper > h4 {
  font-family: "Heebo", sans-serif;
  line-height: 20px;
  width: 50px;
  letter-spacing: 1px;
}

a {
  text-decoration: none;
  color: white;
}

.MenuItems > ul {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  margin: 10px auto 30px;
}

.MenuItems > ul > a {
  cursor: pointer;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 500;
}

.MenuItems > ul > a:hover {
  opacity: 0.7;
}

.mailSocialIcons > h1 {
  width: fit-content;
  margin: 0 auto;
  padding-left: 45px;
  font-size: 28px;
}

.mailSocialIcons > p {
  width: 130px;
  margin: 20px auto 10px;
}

.iconStyles {
  width: 175px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 80px;
}

.iconStyles > a > img {
  cursor: pointer;
  width: 45px;
  height: 45px;
  margin: 20px;
}
.iconStyles > a > img:hover {
  opacity: 0.7;
}
.accordion {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  padding-left: 85px;
  margin: 0 auto 10px;
}
.accItem {
  border: none;
}
.accHeading {
  cursor: pointer;
  /* width: 130px; */
  text-align: center;
  padding: 10px;
}
.accHeading:hover {
  color: #49dcf6;
}
.accBtnInner:hover {
  color: #49dcf6;
}
.ac .accPanel {
  width: 100px;
}
.accBtn {
  border: none;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 0;
}
.accBtnInner {
  cursor: pointer;
  color: #fff;
  margin: 10px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  margin-left: 30px;
  margin-top: 20px;
}
.accBtnInner:hover {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .menuStyles {
    height: 100vh;
  }
  .MenuItemsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    width: 80%;
    margin: 0 auto;
  }
  .MenuItems > ul > a {
    font-size: 24px;
    margin: 15px 0 15px 40px;
  }
  .mailSocialIcons > h1 {
    width: 290px;
  }
  .mailSocialIcons > p {
    width: 180px;
    font-size: 22px;
    margin: 20px auto 20px;
  }
  .iconStyles {
    width: 290px;
    justify-content: start;
    padding-left: 35px;
  }
  .accordion {
    margin-bottom: 70px;
  }
  .accBtn {
    font-size: 20px;
  }
  .accBtnInner {
    font-size: 20px;
  }
  .mailSocialIcons {
    margin-top: 50px;
  }

  .mailSocialIcons > p {
    font-size: 25px;
  }
}

@media (min-width: 1024px) {
  .accBtnInner {
    margin-left: 50px;
  }
}
