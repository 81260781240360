.mainWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: white;
}
.contentWrapper > img {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.mobile > img {
  padding: 20px;
  width: 100%;
}
.desktop > img {
  display: none;
  width: 100%;
  max-width: 1400px;
}

.toolsWrapper {
  background-color: #fff;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1em;
  justify-content: space-between;
  margin: 0px auto;
  padding: 10px;
}
.mobileTools {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}
.desktopTools {
  display: none;
}

@media screen and (min-width: 768px) {
  .whatwedoContentWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    justify-content: space-between;
    background-color: #fff;
  }
  .mobile > img {
    display: none;
  }
  .desktop > img {
    display: block;
    padding: 20px;
    margin: 0 auto 20px;
    max-width: 1150px;
  }
  .mobileTools {
    display: none;
  }
  .desktopTools {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    display: block;
  }
}

/* @media (min-width: 1024px) {

} */
@media (min-width: 1200px) {
  .desktopTools {
    padding: 0;
  }
  .desktop > img {
    margin-bottom: 40px;
  }
}
