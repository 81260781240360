.privacyWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}
.privacyBg {
  background: linear-gradient(
      92.03deg,
      rgba(0, 0, 0, 0.267) 1.47%,
      rgba(0, 0, 0, 0) 98.31%
    ),
    url("../../images/privacy/privacy-ng.jpg") no-repeat;
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: left;
}

.mainStyles {
  max-width: 1200px;
  margin: 10px auto 0;
  padding: 25px;
}
.privacyContent > h1 {
  text-align: center;
  margin: -30px auto 0;
  background-color: #11557c;
  width: fit-content;
  padding: 10px 20px;
  color: #fff;
}
.mainContent > h2,
h3 {
  color: #6007b1;
  margin-top: 30px;
  margin-bottom: 5px;
}
.mainContent > h3 {
  font-weight: 500;
}
.mainContent > p {
  text-align: justify;
  margin-bottom: 5px;
}
.mainContent > ul {
  margin-left: 25px;
}
.mainContent > ul > li {
  font-size: 17px;
  line-height: 25px;
}
@media (min-width: 768px) {
  .mainContent > ul > li {
    font-size: 20px;
    line-height: 35px;
  }
  .mainContent > p {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .privacyContent > h1 {
    margin-top: -40px;
  }
  .mainContent > p {
    font-size: 20px;
  }
  .mainContent > ul > li {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .privacyBg {
    height: 400px;
  }
  .mainContent > p {
    font-size: 20px;
  }
}
