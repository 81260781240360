.mainWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}
.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.mobile > img {
  width: 100%;
  margin: 0 auto;
  padding: 25px;
}
.desktop > img {
  display: none;
  width: 100%;
  max-width: 1100px;
  margin: 0;
  padding: 25px;
}
.desktopAI > img {
  display: none;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 25px;
}
.flowchart > img {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px 25px 25px;
}
.contentWrapper > p {
  padding: 20px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .desktop > img {
    display: block;
  }
  .desktopAI > img {
    display: block;
  }
}
@media (min-width: 1200px) {
  .contentWrapper > p {
    padding-left: 0;
  }
}
