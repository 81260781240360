.ideaLeftContainer {
    width: 310px;
    margin: 25px auto;
}
.leftInfo {
    padding: 14px 0;
    align-self: center;
    margin-left: 2px;
    border: 3px solid #6007B1;
    border-radius: 0px 30px 30px 0px;
    padding-left: 55px;
    border-left: none;
    height: 135px;
}
.leftInfo h4 {
    color: #003980;
    text-align: left;
    font-weight: 400;
}
.ideaImage {
    width: 180px;
    margin-right: -55px;
    align-self: center;
}
.ideaImage img {
    width: 100%;
}
.leftInfo p {
    text-align: left;
    color: #413F3F;
    font-size: 16px;
    line-height: 21px;
}
.leftWrapper {
    display: flex;
}