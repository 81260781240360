.nephrologyWrapper {
  background-color: #fff;
  max-width: 1400px;
  margin: 0 auto;
}
.nephrologyWrapper > p {
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px;
}
.nephrologyWrapper > p > a {
  color: #11557c;
  font-weight: 500;
}
.nephrologyWrapper > p > a:hover {
  border-bottom: 1px solid #000;
}
.conference {
  color: #fff;
  background: linear-gradient(
      94.88deg,
      rgba(0, 0, 0, 0.5) 2.56%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    url(../../images/newsroom/conference-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.conferenceWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.conferenceWrapper > img {
  display: block;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}
.conferenceContent {
  max-width: 1200px;
  margin: 0 auto;
}

.conferenceContent {
  padding: 10px 25px;
}
.conferenceContent > h1 {
  margin-bottom: 25px;
}

.conferenceContent > h3 {
  color: #fff;
  margin-bottom: 25px;
}

.conferenceDate > p {
  margin-bottom: 10px;
  font-weight: 500;
}
.certificates {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.certificates > img {
  width: 90%;
  margin: 20px auto;
}

@media (min-width: 768px) {
  .conferenceWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conferenceWrapper > img {
    width: 350px;
    margin: 30px 0;
  }
}

@media (min-width: 1024px) {
  .conferenceDate {
    display: flex;
    justify-content: space-between;
    max-width: 550px;
  }
  .certificates {
    flex-direction: row;
  }
  .certificates > img {
    max-width: 450px;
    margin: 30px auto;
  }
}
@media (min-width: 1200px) {
  .conferenceContent {
    padding-right: 60px;
    padding-left: 0;
  }
  .conferenceContent > h3 {
    margin-bottom: 45px;
  }
  .nephrologyWrapper > p {
    padding: 25px 0;
  }
  .certificates > img {
    max-width: 580px;
    margin: 30px auto;
  }
}
