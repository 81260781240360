button {
  background-color: #235087;
  color: #ffffff;
  padding: 10px 20px;
  outline: none;
  border: 2px solid #235087;
  font-size: 16px;
}
button:hover {
  color: #235087;
  background-color: #fff;
  transition: all 0.25s ease-in;
  cursor: pointer;
  border: 2px solid #235087;
}
button:focus {
  color: #235087;
  background-color: #fff;
  transition: all 0.25s ease-in;
}

@media (min-width: 768px) {
  button {
    padding: 10px 35px;
  }
}

@media (min-width: 1024px) {
  button {
    padding: 15px 50px;
  }
}
