.mainWrapper {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #fff;
}
.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
.contentWrapper > p {
  margin: 0 20px;
  padding-bottom: 25px;
}
.contentWrapper > p > span {
  font-weight: 600;
}
.mobile > img {
  width: 100%;
}
.desktop > img {
  display: none;
  width: 100%;
  max-width: 1400px;
}
.crewContent {
  padding-bottom: 30px;
}
.crewContent > h3 {
  color: #6007b1;
  margin: 0 20px 20px;
}
.crewContent > p {
  margin: 0 10px 0 20px;
}
.crew {
  max-width: 1200px;
  margin: 0 auto;
}
.crew h3 {
  text-align: center;
}
.crew h5 {
  color: #6007b1;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
}
.crew p {
  width: 85%;
  margin: 0 auto;
}

.crewContain {
  width: 75px;
  margin-bottom: 15px;
}
.crewContain img {
  width: 100%;
  border-radius: 50%;
}
.crewImages {
  width: 85%;
  background: url("../../images/aboutUs/logo.svg");
  min-height: 255px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
}
.crewContain .crewName {
  font-size: 14px;
  width: max-content;
  text-align: center;
  line-height: inherit;
}
.crewContain .crewDesig {
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
  line-height: inherit;
  text-align: center;
}
.crewImages .sides,
.crewImages .centre {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.crewImages .centre {
  align-self: flex-end;
}
.crewImages .sides {
  max-height: 260px;
}
@media (min-width: 768px) {
  .mobile > img {
    display: none;
  }
  .desktop > img {
    display: block;
    max-width: 1150px;
  }
  .crew h3,
  .crew h5,
  .crew p {
    text-align: left;
    width: 90%;
    margin: 0 auto;
  }
  .crew h5 {
    font-size: 25px;
    margin: 10px auto 20px;
    font-weight: 500;
  }
  .crewImages {
    max-width: 600px;
  }
  .crewContain {
    width: 150px;
  }
  .crewContain .crewName {
    font-size: 20px;
    width: 100%;
  }
  .crewContain .crewDesig {
    font-size: 17px;
    line-height: 20px;
  }
  .crewImages .sides {
    min-height: 450px;
  }
}

@media (min-width: 1024px) {
  .crew h3,
  .crew h5,
  .crew p {
    width: 90%;
    max-width: 1200px;
  }
  .crew h5 {
    margin-bottom: 25px;
  }
  .crewImages {
    width: 90%;
    max-width: 1200px;
  }
  .crewContain {
    width: 240px;
  }
  .crewContain .crewName {
    font-size: 24px;
  }
  .crewContain .crewDesig {
    font-size: 20px;
    line-height: 25px;
  }
  .crewImages .sides {
    min-height: 600px;
  }
}
@media (min-width: 1200px) {
  .contentWrapper > p {
    width: 90%;
    margin-left: 0;
  }
  .crewContent > h3 {
    margin-left: 0;
  }
  .crewContent > p {
    width: 90%;
    margin-left: 0;
  }
  .crew h5 {
    font-size: 32px;
  }
  .crew h5 {
    width: 100%;
    max-width: 1200px;
  }
  .crew p {
    padding: 5px 0;
    width: 100%;
    max-width: 1200px;
  }
}
