.soloLogo {
  width: 70px;
  margin: 0 auto;
}
.soloLogo img {
  width: 100%;
}
.soloLogo img:hover {
  cursor: pointer;
}
.soloLogo p {
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 16px;
}

@media (min-width: 768px) {
  .soloLogo {
    width: 120px;
  }
  .soloLogo p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (min-width: 1024px) {
  .soloLogo {
    width: 180px;
  }
  .soloLogo p {
    font-size: 22px;
    line-height: 30px;
  }
}
